html {
  position: relative;
  min-height: 100%;
}

body {
  /*margin-bottom: 160px !important;  Height of the footer */
  padding: 10px;
}

footer {
  position: relative;
  bottom: 0;
  width: 100%;
  /*height: 160px;  Set the fixed height of the footer here */
}

/* Override Bootstrap Styling

.btn-primary {
  background-color: #025fce !important;
}

button:focus,
button:active {
  outline: none !important;
  box-shadow: none !important;
}
*/

  .card {
    margin-top: 2rem;
    margin-bottom: 0;
    display: inline-block; 
    width: 90%; 
 
  }

  .loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #025fce;
    border-bottom: 16px solid #025fce;
    width: 20px;
    height: 20px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }
  
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }