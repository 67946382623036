.footer {
  background-color: #1d1d1d;
  padding-top: 1rem;
  padding-bottom: 0;
}

.title {
  color: #fff;
}

.description {
  color: #fff;
}

.footerlink,
.footerlink:hover {
  color: #fff;
}
